import gql from "graphql-tag";

export const CgvQuery = gql `
query getCvgPage($lang: I18NLocaleCode) {
  cgvPage(locale: $lang) {
    data {
      id
      attributes {
        content
      }
    }
  }
}
`;



export const LegalNoticeQuery = gql `
query getLegalNoticePage($lang: I18NLocaleCode) {
  legalNoticePage(locale: $lang) {
    data {
      id
      attributes {
        content
      }
    }
  }
}
`;


export const PrivacyPolicy = gql `
query PrivacyPolicy($lang: I18NLocaleCode) {
  privacyPolicy(locale: $lang) {
    data {
      id
      attributes {
        content
        seo {
          permalink
          pageTitle
          discription
        }
      }
    }
  }
}
`;
